import React from "react";
import "./Loader.css";

export const Loader = () => {
  return (
    <div className="flex items-center justify-center">
      <div
        className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
};

export const findingRoutes = () => {
  return (
    <>
      <div className="snippet">
        <div className="stage">
          <div className="dot-bricks"></div>
        </div>

        <div className="animate-pulse text-center">
          <p className="text-lg font-medium bg-gradient-to-r from-[#DC7896] to-[#9E59FE] bg-clip-text text-transparent">
            Finding Routes<span className="opacity-0">...</span>
          </p>
        </div>
      </div>
    </>
  );
};

// export default Loader;
