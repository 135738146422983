import ChainsContext from ".";
import axios from "axios";
import { useEffect, useState } from "react";
import BaseURL from "../BaseUrl/BaseUrl";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useCallback } from "react";
/* global BigInt */

const APIState = ({ children }) => {
  const [chains, setChains] = useState([]);
  const [istestnet, setIstestnet] = useState(false);
  const [fromTokens, setFromTokens] = useState([]);
  const [toTokens, setToTokens] = useState([]);

  const { address } = useAccount();
  const dummyWallet = "0x6c086ea57fd16C9dd3cf0BadfB8182C1F3c2DcEd";
  const [walletAddress, setWalletAddress] = useState(dummyWallet);

  // FROM TOKEN
  const [displayFromChains, setDisplayFromChains] = useState(false);
  const initialFromChain = { id: null, name: "", logoURI: "" };
  const [currFromChain, setCurrFromChain] = useState(initialFromChain);

  const [displayFromTokens, setDisplayFromTokens] = useState(false);
  const currentFromTokenInitial = {
    fromAddress: "",
    fromChainId: "",
    fromDecimals: 0,
    name: "",
    logoURI: "",
  };
  const [currFromToken, setCurrFromToken] = useState(currentFromTokenInitial);

  // TO TOKEN
  const [displayToChains, setDisplayToChains] = useState(false);
  const initialToChain = { id: null, name: "", logoURI: "" };
  const [currToChain, setCurrToChain] = useState(initialToChain);

  const [displayToTokens, setDisplayToTokens] = useState(false);
  const currentToTokenInitial = {
    toAddress: "",
    tochainId: "",
    logoURI: "",
    name: "",
  };
  const [currToToken, setCurrToToken] = useState(currentToTokenInitial);

  const [amount, setAmount] = useState("");

  const [balance, setBalance] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadFromToken, setLoadFromToken] = useState(false);
  const [loadToToken, setLoadToToken] = useState(false);

  // Routes Data

  const routesInitial = {
    fromLogo: "",
    fromAmount: "",
    fromName: "",
    fromDecimal: "",
    toLogo: "",
    toAmountMin: "",
    toName: "",
    toDecimal: "",
    allRoutes: [],
    stepMaps: [],
    details: [],
  };

  const [routesData, setRoutesData] = useState(routesInitial);

  // Routes Error

  const [error, setError] = useState("");

  // async function getBalance() {
  //   const res = await axios.get(
  //     `${BaseURL}api/v1/balance/wallet?chainId=1&address=${walletAddress}`
  //   );
  // }

  // --------- Change Network (Testnet/Mainnet) ---------

  const changeNetwork = () => {
    setIstestnet((prevState) => !prevState);
    setCurrFromChain(initialFromChain);
    setCurrToChain(initialToChain);
    setChains([]);
    setFromTokens([]);
    setToTokens([]);
    setAmount("");
    setRoutesData(routesInitial);
    setError("");
  };

  // Fetch the wallet address only once on component mount
  useEffect(() => {
    async function getWalletAddress() {
      if (address) {
        setWalletAddress(address);
      } else {
        setWalletAddress(dummyWallet);
      }
    }
    getWalletAddress();
  }, [address]);

  // From Chains Token
  const setFromChains = (data) => {
    const { id, name, logoURI } = data;
    setDisplayFromChains((prevFromChains) => !prevFromChains);
    setCurrFromChain({ id, name, logoURI });
  };

  // To Chains Token
  const setToChains = (data) => {
    const { id, name, logoURI } = data;
    setCurrToChain({ id, name, logoURI });
    setDisplayToChains(!displayToChains);
  };

  useEffect(() => {
    async function getChains() {
      try {
        const response = await axios.get(
          `${BaseURL}api/v1/lifi/chains?isTestnet=${istestnet}`
        );
        if (response.data.code === 201) {
          setChains(response.data.chains);
        }
      } catch (error) {
        console.error(error);
      }
    }
    getChains();
  }, [istestnet]);

  useEffect(() => {
    async function getFromTokens() {
      try {
        if (currFromChain.id) setLoadFromToken(true);
        setCurrFromToken(currentFromTokenInitial);
        const response = await axios.get(
          `${BaseURL}api/v1/lifi/tokens?isTestnet=${istestnet}&chainId=${currFromChain.id}`
        );

        if (response.data.code === 201) {
          setFromTokens(response.data.tokens);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadFromToken(false);
      }
    }

    getFromTokens();
  }, [istestnet, currFromChain.id]);

  useEffect(() => {
    async function getToTokens() {
      try {
        if (currToChain.id) setLoadToToken(true);
        setCurrToToken(currentToTokenInitial);
        const response = await axios.get(
          `${BaseURL}api/v1/lifi/tokens?isTestnet=${istestnet}&chainId=${currToChain.id}`
        );
        if (response.data.code === 201) {
          setToTokens(response.data.tokens);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadToToken(false);
      }
    }

    getToTokens();
  }, [istestnet, currToChain.id]);

  const getFromDisplayTokens = (data) => {
    setDisplayFromTokens((prevFrom) => !prevFrom);
    setCurrFromToken({
      fromAddress: data?.address,
      fromChainId: data?.chainId,
      fromDecimals: data?.decimals,
      logoURI: data?.logoURI,
      name: data?.name,
    });
  };

  const getToDisplayTokens = (data) => {
    setDisplayToTokens((prevTo) => !prevTo);
    setCurrToToken({
      toAddress: data?.address,
      tochainId: data?.chainId,
      logoURI: data?.logoURI,
      name: data?.name,
    });
  };

  function getAmount(event) {
    if (event.target.value === "") {
      setAmount("");
    } else {
      setAmount(event.target.value);
    }
  }

  function getRoutes() {
    if (currFromChain.id === null) {
      toast.error("Please select a from chain");
    } else if (currToChain.id === null) {
      toast.error("Please select a to chain");
    } else if (currFromToken.fromChainId === "") {
      toast.error("Please select a from token");
    } else if (currToToken.tochainId === "") {
      toast.error("Please select a to token");
    } else if (amount === "" || amount <= 0) {
      toast.error("Please enter an amount");
    } else {
      setLoading(true);
      setRoutesData(routesInitial);
      setError("");

      // if (address === undefined) {
      //   toast.error("Dummy Routes");
      // }

      console.log(
        BigInt(amount * 10 ** currFromToken?.fromDecimals)?.toString()
      );

      axios
        .get(
          `${BaseURL}api/v1/lifi/routes?isTestnet=${istestnet}&fromChainId=${
            currFromChain.id
          }&toChainId=${currToChain.id}&fromTokenAddress=${
            currFromToken.fromAddress
          }&toTokenAddress=${
            currToToken.toAddress
          }&fromAddress=${walletAddress}&fromAmount=${BigInt(
            amount * 10 ** currFromToken?.fromDecimals
          )?.toString()}`
        )
        .then((res) => {
          // console.log("🚀 ~ .then ~ res:", res);

          if (
            res.data.code === 200 &&
            res.data.message === "No Routes Available"
          ) {
            setError(res.data.message);
            setLoading(false);
            return;
          } else {
            setRoutesData({
              fromLogo: res?.data?.routes[0]?.currFromToken?.logoURI,
              fromAmount: res?.data?.routes[0]?.fromAmount,
              fromName: res?.data?.routes[0]?.currFromToken?.name,
              fromDecimal: res?.data?.routes[0]?.currFromToken?.decimals,
              toLogo: res?.data?.routes[0]?.toToken?.logoURI,
              toAmountMin: res?.data?.routes[0]?.toAmountMin,
              toName: res?.data?.routes[0]?.toToken?.name,
              toDecimal: res?.data?.routes[0]?.toToken?.decimals,
              allRoutes: res?.data?.routes,
              stepMaps: res?.data?.stepMaps,
              details: res?.data?.details,
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          setError(err);
          console.log("-----------", err, "------------");
          setLoading(false);
        });
    }

    // const timer = setInterval(function () {
    //   getRoutes();
    //   return () => clearInterval(timer);
    // }, 30_000);
  }

  function selectIndex(item) {
    // console.log("🚀 ~ selectIndex ~ index:", routesData.allRoutes[item]);
    return routesData.allRoutes[item];
  }

  return (
    <ChainsContext.Provider
      value={{
        istestnet,
        changeNetwork,
        chains,
        fromTokens,
        toTokens,
        address,
        dummyWallet,
        walletAddress,

        getFromDisplayTokens,
        currFromToken,
        displayFromTokens,
        setDisplayFromTokens,

        currToChain,
        displayToChains,
        setDisplayToChains,
        setToChains,
        loadFromToken,

        getToDisplayTokens,
        currToToken,
        setDisplayToTokens,
        displayToTokens,
        loadToToken,

        getAmount,
        getRoutes,
        selectIndex,
        loading,
        amount,
        routesData,
        error,
        setFromChains,
        setDisplayFromChains,
        displayFromChains,
        currFromChain,
      }}
    >
      {children}
    </ChainsContext.Provider>
  );
};

export default APIState;
