import React, { useContext, useEffect, useState } from "react";
import { findingRoutes } from "../../Components/Loader/Loader";
import ChainsContext from "../../Context";
import "./SideBar.css";
import SwapPopup from "../Execution/SwapPopup/SwapPopup";
import SocialLogin from "@biconomy/web3-auth";

const Sidebar = () => {
  const getRoutes = useContext(ChainsContext);
  const { loading, routesData, error } = getRoutes;

  const [isOpen, setIsOpen] = useState(false);
  const [selectRoute, setSelectRoute] = useState("");

  const socialLogin = new SocialLogin();

  //  -------------- Steps Start --------------

  //  -------------- Steps End --------------

  const { fromAmount, allRoutes, stepMaps, details } = routesData;

  const minimalSteps = stepMaps.slice(1);
  const minimalDetails = details.slice(1);

  const logoSize = "w-8 h-8 rounded-full !z-10";
  const nestedGrid =
    "bg-[#0101019c] border border-[#ffffff33] rounded-3xl p-6 cursor-pointer";

  const dummyNestedGrid =
    "bg-[#0101019c] border border-[#ffffff33] rounded-3xl p-6 cursor-not-allowed h-96";

  function selectIndex(item) {
    setIsOpen(true);
    setSelectRoute(allRoutes[item]);
  }

  return (
    <>
      {isOpen && (
        <SwapPopup
          closeModal={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
          data={selectRoute}
        />
      )}

      <div className="bg-[#00000033] border border-[#ffffff33] rounded-3xl text-white px-10 min-h-[384px] text-center">
        <div className="manrope">
          <>
            <div className="my-10 relative">
              <div className="absolute top-0 right-0">
                {details[0]?.tag && (
                  <h2 className="bg-green-600 py-1 px-4 rounded-md ">
                    {details[0]?.tag}
                  </h2>
                )}
              </div>

              <h2 className="font-medium text-xl">
                {!loading && fromAmount && "Optimal Route Plan"}
              </h2>

              <div className="mt-10 flex justify-between gap-x-6 relative overflow-x-auto py-4 w-auto">
                {!loading && fromAmount && (
                  <div className="absolute top-1/2 min-w-[99%] h-[1px] bg-red-500 ml-1"></div>
                )}
                {stepMaps[0]?.map((step, index) => (
                  <div
                    className={`py-3 px-1 z-[100] min-w-[160px] max-w-[160px] !box-border bg-[#0101019c] rounded-md text-xs overflow-x-auto`}
                    key={index}
                  >
                    <div>
                      <img
                        src={step.logoURI}
                        alt="logoURI"
                        className={`${logoSize} mx-auto`}
                      />
                    </div>
                    <h3 className="mt-1">
                      {step?.chainId && (
                        <span className="text-purple-500 mr-1">
                          chainID {step?.chainId}
                        </span>
                      )}
                      {step.name}
                    </h3>

                    <div className="mt-2">
                      {step.amount && (
                        <h2>
                          Amount : {step.amount.toFixed(3)} {step.name}
                        </h2>
                      )}
                      {step.amountUSD && (
                        <h2>Value : {step.amountUSD.toFixed(3)} $</h2>
                      )}
                      {step.slippage && <h2>Slippage : {step.slippage}</h2>}

                      {step.executionDuration && (
                        <h2>Time : {step.executionDuration} secs</h2>
                      )}
                      {step.gasCostUSD && (
                        <h2>Gas cost : {step.gasCostUSD.toFixed(2)} $</h2>
                      )}
                      {step.serviceCostUSD !== undefined && (
                        <h2>
                          Service Cost : {step.serviceCostUSD.toFixed(2)} $
                        </h2>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="text-center mt-10">
                {fromAmount && (
                  <button
                    className={`ui-btn-hover btn-color px-6 py-2 ${
                      fromAmount === "" && "!cursor-not-allowed"
                    }`}
                    // disabled={fromAmount === ""}
                    onClick={() => selectIndex(0)}
                  >
                    SWAP
                  </button>
                )}
              </div>
            </div>

            {error && (
              <div className="pt-14">
                <p className="text-center">{error}</p>
              </div>
            )}

            {loading && <div className="h-full">{findingRoutes()}</div>}
          </>
        </div>
      </div>

      {/* Path Details */}

      <h1 className="manrope my-10">Path Details</h1>
      <div className="text-white grid grid-cols-3 gap-10">
        {stepMaps.length === 0 && (
          // Dummy Data
          <>
            <div className={dummyNestedGrid}></div>
            <div className={dummyNestedGrid}></div>
            <div className={dummyNestedGrid}></div>
          </>
        )}

        {minimalSteps?.map((item, index) => (
          <div
            className={`${nestedGrid} hover:bg-blue-100 hover:text-black duration-300`}
            key={index}
            onClick={() => selectIndex(index + 1)}
          >
            {minimalDetails.map((mStep, mIndex) =>
              index === mIndex && mStep.tag ? (
                <div className="flex justify-start items-center">
                  <h5 className="bg-red-600 py-1 px-4 rounded-md">
                    {mStep.tag}
                  </h5>
                </div>
              ) : null
            )}

            <div>
              {item?.map((step, stepIndex) => (
                <div className="zigzag-timeline__item" key={stepIndex}>
                  <div className="zigzag-timeline__milestone">
                    <img
                      src={step.logoURI}
                      alt="logoURI"
                      className={logoSize}
                    />
                  </div>
                  <div
                    className={`py-7 text-xs ${
                      stepIndex % 2 === 0 ? "text-right" : "text-left"
                    }`}
                  >
                    <h3>
                      {step?.chainId && (
                        <span className="text-purple-500 text-xs mr-1">
                          chainID {step?.chainId}
                        </span>
                      )}
                      {step.name}
                    </h3>

                    {step.amount && (
                      <h2>
                        Amount : {step.amount.toFixed(3)} {step.name}
                      </h2>
                    )}

                    {step.amountUSD && (
                      <h2>Value : {step.amountUSD.toFixed(3)} $</h2>
                    )}

                    {step.slippage && <h2>Slippage : {step.slippage}</h2>}

                    {step.executionDuration && (
                      <h2>Time : {step.executionDuration} secs</h2>
                    )}

                    {step.gasCostUSD && (
                      <h2>Gas cost : {step.gasCostUSD.toFixed(2)} $</h2>
                    )}

                    {step.serviceCostUSD !== undefined && (
                      <h2>Service Cost : {step.serviceCostUSD.toFixed(2)} $</h2>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Sidebar;
