import React from "react";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import Sidebar from "../SideBar/Sidebar";

const Home = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="grid grid-cols-3 place-items-start py-10">
          <div className="place-content-start">
            <LeftSideBar />
          </div>

          <div className="col-span-2 w-full h-full text-white">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
