import React, { useState } from "react";
import Container from "../../UI/Common/Container/Container";
import useFetch from "../../hooks/useFetch";
import BaseUrl from "../../BaseUrl/BaseUrl";
import { useAccount } from "wagmi";
import moment from "moment/moment";
import { Loader } from "../Loader/Loader";

const Transactions = () => {
  const { address } = useAccount();

  const [data, isLoading, error] = useFetch(
    `${BaseUrl}api/v1/swaps/user?address=${address}`
  );

  const [isOpen, setIsOpen] = useState([
    Array(data?.swaps?.length).fill(false),
  ]);

  const timeStampDate = (date) => {
    return moment(Number(date)).format("MMMM Do YYYY, h:mm a");
  };

  const handleBoxClick = (index) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };

  return (
    <Container>
      <div className="text-white mt-10 px-28">
        {isLoading && (
          <div className=" flex justify-center items-center">{Loader()}</div>
        )}

        {!isLoading && (
          <div className="bg-[#6b6b6b12] w-full border border-white/20 rounded-xl px-8 py-6">
            <div>
              {data?.swaps ? (
                data?.swaps?.map((item, index) => (
                  <React.Fragment key={index}>
                    <div
                      className="px-4 py-4 cursor-pointer my-10 bg-white/5 rounded-xl relative"
                      onClick={() => {
                        handleBoxClick(index);
                      }}
                    >
                      <div
                        className={`${
                          isOpen[index] ? "rotate-180" : ""
                        } transition-all duration-300 ease-in-out absolute right-2 top-6`}
                      >
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                      <div>
                        <div className="flex gap-x-5 items-center">
                          <div className="relative">
                            <img
                              src={item?.fromToken?.logoURI}
                              alt="logo"
                              className="w-10 h-10 rounded-full border-2 border-black box-border"
                            />
                            <img
                              src={item?.toToken?.logoURI}
                              alt="logo"
                              className="w-10 h-10 rounded-full border-2 border-black box-border absolute top-3 left-3"
                            />
                          </div>

                          <div>
                            <h5 className="font-medium">Swapped</h5>
                            <h6 className="text-white/40">
                              From {item?.fromToken?.name} to {""}
                              {item?.toToken?.name}
                            </h6>
                          </div>
                        </div>

                        <div
                          className={`${
                            isOpen[index] ? "max-h-40" : "max-h-0"
                          } transition-all duration-500 ease-in-out`}
                        >
                          {isOpen[index] && (
                            <div className="grid grid-cols-4 py-3">
                              <div className="flex flex-col justify-between gap-5">
                                <h4 className="text-[#B1B1B1]">Swap From</h4>
                                <h5 className="text-base">
                                  {item?.fromAddress.slice(0, 4)}...
                                  {item?.fromAddress.slice(-4)}
                                </h5>
                              </div>

                              <div className="flex flex-col justify-between gap-5">
                                <h4 className="text-[#B1B1B1]">Swap To</h4>
                                <h5 className="text-base">
                                  {item?.toAddress.slice(0, 4)}...
                                  {item?.toAddress.slice(-4)}
                                </h5>
                              </div>

                              <div className="flex flex-col justify-between gap-5">
                                <h4 className="text-[#B1B1B1]">Gas</h4>
                                <h5 className="text-base">
                                  {item?.gasCostUSD} $
                                </h5>
                              </div>

                              <div className="flex flex-col justify-between gap-5">
                                <h4 className="text-[#B1B1B1]">Date</h4>
                                <h5 className="text-base">
                                  {timeStampDate(item?.timestamp)}
                                </h5>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <div
                        className={`${
                          isOpen[index] ? "rotate-180" : ""
                        } transition-all duration-1000 ease-in-out`}
                      >
                        <i className="fa-solid fa-chevron-down"></i>
                      </div> */}
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="text-center">No Transactions</div>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Transactions;
