import React, { useEffect, useRef, useState } from "react";
import {
  useAccount,
  useNetwork,
  useSigner,
  useProvider,
  useSwitchNetwork,
} from "wagmi";
import LIFI from "@lifi/sdk";
import BaseURL from "../../../BaseUrl/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader } from "../../../Components/Loader/Loader";
import "./SwapPopup.css";

const SwapPopup = ({ closeModal, data }) => {
  const {
    fromAmount,
    fromAmountUSD,
    toAmountUSD,
    gasCostUSD,
    toAmountMin,
    fromToken,
    toToken,
    steps,
  } = data;

  const [loadingSwap, setLoadingSwap] = useState(false);
  const { address } = useAccount();

  const lifi = new LIFI({
    apiUrl: "https://staging.li.quest/v1/",
    // apiUrl: "https://li.quest/v1/",
    rpcs: {
      80001: [
        "https://polygon-mumbai.g.alchemy.com/v2/mzEXZ_zrq-Ty3h6oryxzgQoAHCZynyVK",
      ],
      5: [
        "https://eth-goerli.g.alchemy.com/v2/EdfJsf-h3CW2xu1QFsWaGaS9kp0G8aRl",
      ],
    },
  });

  const [selectedRoute, setSelectedRoute] = useState("");

  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { data: signer } = useSigner();
  const switchChainHook = (requiredChainId) => {
    // console.log(chain);
    if (chain.id !== requiredChainId) {
      switchNetwork(requiredChainId);
    }
    return signer;
  };

  const executeRoute = async (chosenRoute) => {
    // console.log("🚀 ~ executeRoute ~ chosenRoute:", chosenRoute);

    if (address === undefined) {
      toast.warn("Please connect the wallet and try again");
      closeModal();
    } else {
      try {
        // console.log(chain);
        if (chain.id !== chosenRoute.fromChainId) {
          switchNetwork(chosenRoute.fromChainId);
        }

        toast.success("Swap has been started");
        setLoadingSwap(true);

        const executingRoute = await lifi.executeRoute(signer, chosenRoute, {
          ...switchChainHook,
          ...updateCallback,
        });

        console.log("---executingRoute---", executingRoute);
      } catch (err) {
        console.log(err);
        toast.error("Swap was canceled");
      } finally {
        setLoadingSwap(false);
      }
    }
  };

  const resumeRoute = async (chosenRoute) => {
    try {
      // console.log(chosenRoute);
      if (chain.id !== chosenRoute.fromChainId) {
        switchNetwork(chosenRoute.fromChainId);
      }
      const resumingRoute = await lifi.resumeRoute(signer, chosenRoute, {
        ...switchChainHook,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // const updateCallback = (updatedRoute) => {
  //   console.log(updatedRoute);
  //   console.log("Ping! Everytime a status update is made!");
  // };

  const updateCallback = (updatedRoute) => {
    console.log(updatedRoute);

    const url = `${BaseURL}api/v1/swaps/update`;
    const data = updatedRoute;

    axios
      .post(url, data)
      .then((response) => {
        console.log(response);
        toast.success("Swap has been completed");
      })
      .catch((error) => {
        console.error("--->>> error", error);
        // toast.error()
      });

    console.log("Ping! Everytime a status update is made!");
  };

  return (
    <>
      {loadingSwap && (
        <div className=" flex justify-center items-center">{Loader()}</div>
      )}
      <div className="fixed backdrop-filter backdrop-blur-md bg-black bg-opacity-75 transition-opacity bg-backdrop flex items-center justify-center overflow-auto z-[1100] inset-0 fadeIn">
        <div
          className="relative transition-all"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-3xl bg-[#433240] text-white shadow-xl transition-all min-w-[400px] min-h-[250px] flex flex-col justify-center manrope border-2 border-[#ffffff33]">
                {/* <i
                className="fa-sharp fa-solid fa-xmark absolute top-0 right-2 text-2xl cursor-pointer text-white font-bold"
                onClick={closeModal}
              ></i> */}
                <h4 className="uppercase mt-4">Confirm Your Swap</h4>

                <div className="min-w-[300px] mx-auto">
                  <div className="mt-6">
                    <h5 className="text-left">Swap From</h5>
                    <div className="flex justify-between bg-[#0000002b] py-2 px-4 rounded-xl mt-2">
                      <div className="flex items-center gap-x-2">
                        <img
                          src={fromToken?.logoURI}
                          alt=""
                          className="w-7 h-7"
                        />
                        <p className="text-sm">{fromToken?.name}</p>
                      </div>
                      <div className="flex flex-col gap-x-2 text-xs text-right">
                        <p>
                          {(fromAmount / 10 ** fromToken?.decimals)?.toFixed(3)}
                        </p>

                        <p>~ $ {Number(fromAmountUSD)?.toFixed(3)}</p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6">
                    <h5 className="text-left">Swap To</h5>
                    <div className="flex justify-between bg-[#0000002b] py-2 px-4 rounded-xl mt-2">
                      <div className="flex items-center gap-x-2">
                        <img
                          src={toToken?.logoURI}
                          alt=""
                          className="w-7 h-7 rounded-full"
                        />
                        <p className="text-sm">{toToken?.name}</p>
                      </div>
                      <div className="flex flex-col gap-1 text-xs text-right">
                        <p>
                          {(toAmountMin / 10 ** toToken?.decimals)?.toFixed(3)}
                        </p>

                        <p>~ $ {Number(toAmountUSD)?.toFixed(3)}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="min-w-[300px] mx-auto mt-6 text-xs">
                  <div className="flex justify-between">
                    <div className="text-left">
                      <h2>Output value</h2>
                      <h3>{toAmountUSD} $</h3>
                    </div>

                    <div className="text-right">
                      <h2>Gas Fees</h2>
                      <h3>{gasCostUSD} $</h3>
                    </div>
                  </div>

                  <div className="flex justify-between mt-4">
                    <div className="text-left">
                      <h2>Minimum Received</h2>
                      <h3>
                        {(toAmountMin / 10 ** toToken?.decimals)?.toFixed(3)}{" "}
                        {toToken?.name}
                      </h3>
                    </div>

                    <div className="text-right">
                      <h2>Slippage</h2>
                      <h3>{steps[0]?.action?.slippage} %</h3>
                    </div>
                  </div>
                </div>
                <div className="py-4 flex justify-center gap-x-4 px-6 mt-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-[#00000042] px-3 py-2 text-sm font-medium shadow-sm sm:ml-3 sm:w-auto"
                    onClick={closeModal}
                  >
                    Discard
                  </button>
                  <button
                    type="button"
                    className="mt-3 px-3 py-2 shadow-sm sm:mt-0 sm:w-auto ui-btn-hover btn-color text-white"
                    onClick={() => executeRoute(data)}
                  >
                    CONFIRM
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapPopup;
