import { useState, useEffect, useMemo } from "react";
import axios from "axios";

const useFetch = (url, method = "get", body = null) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios({
        method,
        url,
        data: body,
      });
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const memoizedFetchData = useMemo(() => fetchData, [url, method, body]);

  useEffect(() => {
    memoizedFetchData();
  }, [memoizedFetchData]);

  return [data, isLoading, error];
};

export default useFetch;
