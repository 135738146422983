import React from "react";
import LeftSideBar from "./Layouts/LeftSideBar/LeftSideBar";
import NavBar from "./Layouts/NavBar/NavBar";
import Sidebar from "./Layouts/SideBar/Sidebar";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Layouts/Home/Home";
import Transactions from "./Components/Transactions/Transactions";
import "@biconomy/web3-auth/dist/src/style.css";

const App = () => {
  return (
    <>
      {/* limit={3} */}
      <ToastContainer autoClose={2500} transition={Zoom} />

      <div className="bg-home">
        <NavBar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/transactions" element={<Transactions />} />
        </Routes>
      </div>

      {/* <SelectComponent /> */}
    </>
  );
};

export default App;
