import React, { useContext, useEffect, useRef } from "react";
import ChainsContext from "../../Context";
import { Loader } from "../../Components/Loader/Loader";
import "./LeftSideBar.css";
import useClickOutside from "../../hooks/useOutSideClick";
// Dummy wallet address : 0x6c086ea57fd16C9dd3cf0BadfB8182C1F3c2DcEd

const LeftSideBar = () => {
  const fromChainsRef = useRef(null);
  const toChainsRef = useRef(null);
  const fromTokensRef = useRef(null);
  const toTokensRef = useRef(null);

  const getChainsAPI = useContext(ChainsContext);

  const {
    istestnet,
    chains,
    fromTokens,
    toTokens,
    changeNetwork,
    getAmount,
    getRoutes,
    amount,
    routesData,
    walletAddress,
    dummyWallet,
    setFromChains,
    setDisplayFromChains,
    displayFromChains,
    currFromChain,
    getFromDisplayTokens,
    currFromToken,
    displayFromTokens,
    setDisplayFromTokens,
    loadFromToken,

    currToChain,
    displayToChains,
    setDisplayToChains,
    setToChains,

    getToDisplayTokens,
    currToToken,
    setDisplayToTokens,
    displayToTokens,
    loadToToken,
  } = getChainsAPI;

  const { allRoutes, stepMaps } = routesData;
  // console.log("🚀 ~ LeftSideBar ~ stepMaps:", stepMaps[0]);

  let serviceCostArr = [];

  stepMaps[0]?.map((item) => {
    serviceCostArr.push(item?.serviceCostUSD);
  });

  const totalServiceCost = serviceCostArr.reduce(
    (accumulator, currentValue) => {
      if (typeof currentValue === "number") {
        return accumulator + currentValue;
      } else {
        return accumulator;
      }
    },
    0
  );

  const optimalData = allRoutes[0];

  useClickOutside([fromChainsRef], () => {
    setDisplayFromChains(false);
  });

  useClickOutside([toChainsRef], () => {
    setDisplayToChains(false);
  });

  useClickOutside([fromTokensRef], () => {
    setDisplayFromTokens(false);
  });

  useClickOutside([toTokensRef], () => {
    setDisplayToTokens(false);
  });

  const darkBox =
    "bg-[#0000002B] flex justify-between px-4 py-3 mt-4 rounded-lg ";

  const title = "font-medium pt-2";
  const lightBox = "w-[200px] bg-[#FFFFFF14] text-white text-xs rounded-md";

  const innerLightBox =
    "w-full text-center rounded-lg shadow-sm cursor-pointer h-[40px] flex items-center tracking-wider";

  const imgName = "flex gap-x-3 items-center py-2";

  const displayClass =
    "w-full py-2 overflow-x-hidden overflow-y-auto font-normal leading-none shadow-md rounded-xl text-xs max-h-60 decorated";

  const boxImgName =
    "flex items-center w-full px-4 py-2 space-x-3 cursor-pointer transition-all rounded-md hover:bg-white/60 hover:text-black tracking-wider";

  const logoSize = "w-6 h-6 rounded-full object-cover";

  const bestDataDiv = "flex justify-between gap-1";

  return (
    <>
      <div className="bg-[#00000033] w-[450px] py-4 px-10 rounded-3xl shadow-xl">
        <div className="text-white">
          <div className="text-center">
            <button
              className={`px-4 py-2 rounded-md ${
                istestnet
                  ? "bg-red-700 hover:bg-red-300"
                  : "bg-green-700 hover:bg-green-300"
              } hover:text-black transition duration-500`}
              onClick={() => {
                changeNetwork();
              }}
            >
              Currently on {istestnet ? "Testnet" : "Mainnet"}
            </button>

            {/* <h2 className="mt-2">Total Balance : </h2> */}
          </div>

          <div className="text-center">
            {/* swap (select from) */}
            <div className={darkBox}>
              <div className={title}>Swap From</div>

              <div className={lightBox}>
                <div
                  className="flex justify-between items-center px-4 cursor-pointer"
                  onClick={() => {
                    setDisplayFromChains((prevDisplayFrom) => !prevDisplayFrom);
                  }}
                >
                  <div className={innerLightBox}>
                    {currFromChain.id ? (
                      <div className={imgName}>
                        <img
                          src={currFromChain.logoURI}
                          alt=""
                          className={logoSize}
                        />
                        <p>{currFromChain.name}</p>
                      </div>
                    ) : (
                      <div>Select Chain</div>
                    )}
                  </div>
                  <div
                    className={`${
                      displayFromChains ? "rotate-180" : ""
                    } transition-all duration-300 ease-in-out`}
                  >
                    <i className="fa-solid fa-chevron-down"></i>
                  </div>
                </div>

                <div
                  className={`${
                    displayFromChains
                      ? "max-h-60 opacity-100"
                      : "max-h-0 opacity-0"
                  } transition-all duration-300 ease-linear`}
                >
                  {displayFromChains && (
                    <div ref={fromChainsRef}>
                      <div
                        className={`${displayClass} transition-all duration-300`}
                      >
                        {chains.map((chain, index) => {
                          return (
                            <div
                              onClick={() => setFromChains(chain)}
                              key={index}
                              className={boxImgName}
                            >
                              <img
                                className={logoSize}
                                src={chain.logoURI}
                                alt="logo"
                              />
                              <div>{chain.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* swap (select to) */}

            <div className={darkBox}>
              <div className={title}>Swap To</div>

              <div className={lightBox}>
                <div
                  className="flex justify-between items-center px-4 cursor-pointer"
                  onClick={() => {
                    setDisplayToChains((prevValue) => !prevValue);
                  }}
                >
                  <div className={innerLightBox}>
                    {currToChain.id ? (
                      <div className={imgName}>
                        <img
                          src={currToChain.logoURI}
                          alt=""
                          className={logoSize}
                        />
                        <p>{currToChain.name}</p>
                      </div>
                    ) : (
                      <h3>Select Chain</h3>
                    )}
                  </div>
                  <div
                    className={`${
                      displayToChains ? "rotate-180" : ""
                    } transition-all duration-300 ease-in-out`}
                  >
                    <i className="fa-solid fa-chevron-down"></i>
                  </div>
                </div>
                <div
                  className={`${
                    displayToChains
                      ? "max-h-60 opacity-100"
                      : "max-h-0 opacity-0"
                  } transition-all duration-300 ease-linear`}
                >
                  {displayToChains && (
                    <div ref={toChainsRef}>
                      <div className={displayClass}>
                        {chains.map((chain, index) => {
                          return (
                            <div
                              onClick={() => setToChains(chain)}
                              key={index}
                              className={boxImgName}
                            >
                              <img
                                className={logoSize}
                                src={chain.logoURI}
                                alt="logo"
                              />
                              <div>{chain.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* From Token */}

            <div className={darkBox}>
              <div className={title}>From Token</div>

              <div className={lightBox}>
                <div
                  className="flex justify-between items-center px-4 cursor-pointer"
                  onClick={() => setDisplayFromTokens(!displayFromTokens)}
                >
                  <div className={innerLightBox}>
                    {loadFromToken && (
                      <div className="display items-center justify-center">
                        {Loader()}
                      </div>
                    )}
                    {!loadFromToken && !currFromToken.fromChainId && (
                      <div>Select a Token</div>
                    )}

                    {!loadFromToken && currFromToken.fromChainId && (
                      <div className={imgName}>
                        <img
                          src={currFromToken.logoURI}
                          alt=""
                          className={logoSize}
                        />
                        <p className="text-white">{currFromToken.name}</p>
                      </div>
                    )}
                  </div>

                  <div
                    className={`${
                      displayFromTokens ? "rotate-180" : ""
                    } transition-all duration-300 ease-in-out`}
                  >
                    <i className="fa-solid fa-chevron-down"></i>
                  </div>
                </div>

                <div
                  className={`${
                    displayFromTokens
                      ? "max-h-60 opacity-100"
                      : "max-h-0 opacity-0"
                  } transition-all delay-100 duration-300 ease-linear`}
                >
                  {displayFromTokens && (
                    <div ref={fromTokensRef}>
                      <div className={displayClass}>
                        {fromTokens?.map((token, index) => {
                          return (
                            <div
                              onClick={() => getFromDisplayTokens(token)}
                              key={index}
                              className={boxImgName}
                            >
                              <img
                                className={logoSize}
                                src={token.logoURI}
                                alt="logo"
                              />
                              <div>{token.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* To Token */}

            <div className={darkBox}>
              <div className={title}>To Token</div>

              <div className={lightBox}>
                <div
                  className="flex justify-between items-center px-4 cursor-pointer"
                  onClick={() => setDisplayToTokens(!displayToTokens)}
                >
                  <div className={innerLightBox}>
                    {loadToToken && <div className="pt-1">{Loader()}</div>}

                    {!loadToToken && !currToToken.tochainId && (
                      <div>Select a Token</div>
                    )}

                    {!loadToToken && currToToken.tochainId && (
                      <div className={imgName}>
                        <img
                          src={currToToken.logoURI}
                          alt=""
                          className={logoSize}
                        />
                        <p>{currToToken.name}</p>
                      </div>
                    )}
                  </div>

                  <div
                    className={`${
                      displayToTokens ? "rotate-180" : ""
                    } transition-all duration-300 ease-in-out`}
                  >
                    <i className="fa-solid fa-chevron-down"></i>
                  </div>
                </div>

                <div
                  className={`${
                    displayToTokens
                      ? "max-h-60 opacity-100"
                      : "max-h-0 opacity-0"
                  } transition-all duration-300 ease-linear`}
                >
                  {displayToTokens && (
                    <div ref={toTokensRef}>
                      <div className={displayClass}>
                        {toTokens?.map((token, index) => {
                          return (
                            <div
                              onClick={() => getToDisplayTokens(token)}
                              key={index}
                              className={boxImgName}
                            >
                              <img
                                className={logoSize}
                                src={token.logoURI}
                                alt="logo"
                              />
                              <div>{token.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="text-center mt-4 font-medium">
            {address === dummyWallet ? "Wallet Address : " : "Dummy Wallet : "}{" "}
            <span className="bg-gradient-to-r from-[#DC7896] to-[#9E59FE] text-transparent bg-clip-text transition duration-300">
              {`${address.slice(0, 4)} ...${address.slice(-4)}`}
            </span>
          </div> */}

          <div className="text-center mt-4">
            <input
              type="number"
              className="text-black outline-none px-2 rounded-md py-2 caret-yellow-900 text-sm border-2 border-transparent focus:border-blue-500 box-border"
              value={amount}
              placeholder="Enter an amount"
              onChange={getAmount}
            />
          </div>

          {walletAddress === dummyWallet && (
            <p className="text-red-500 manrope text-sm text-center mt-4">
              You are not connected with wallet
            </p>
          )}

          <div className="text-center flex justify-center">
            <button
              onClick={getRoutes}
              type="submit"
              className="mt-4 rounded-md ui-btn flex justify-center"
            >
              <button className="ui-btn-hover btn-color px-4 py-2">
                FIND ROUTES
              </button>
            </button>
          </div>
        </div>

        {/* toAmountUSD,gasCostUSD,toAmountMin,steps[0].action.slippage */}

        <div className="mt-10 text-white text-xs leading-5">
          <h2 className="text-base text-center font-medium text-blue-500">
            Optimal Route Details
          </h2>

          <div className={bestDataDiv}>
            <h2>Output value : </h2>
            <h3>{optimalData?.toAmountUSD || "-"} $</h3>
          </div>

          <div className={bestDataDiv}>
            <h2>Gas Estimate : </h2>
            <h3>{optimalData?.gasCostUSD || "-"} $</h3>
          </div>

          <div className={bestDataDiv}>
            <h2>Minimum Received : </h2>
            <h3>
              {optimalData?.toAmountMin
                ? (
                    optimalData?.toAmountMin /
                    10 ** optimalData?.toToken?.decimals
                  ).toFixed(3)
                : "-"}{" "}
              {optimalData?.toToken?.name || "-"}
            </h3>
          </div>

          <div className={bestDataDiv}>
            <h2>Slippage : </h2>
            <h3>{optimalData?.steps[0]?.action?.slippage || "-"} %</h3>
          </div>

          <div className={bestDataDiv}>
            <h2>Service Cost : </h2>
            <h3>{totalServiceCost || 0}&nbsp;$</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSideBar;
