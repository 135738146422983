import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/home/muwplogo.png";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import "./NavBar.css";

const NavBar = () => {
  const navElements = [
    // {
    //   name: "swap",
    //   path: "",
    // },
    {
      name: "BOT",
      path: "bot",
    },
    {
      name: "transactions",
      path: "transactions",
    },
    {
      name: "Networks",
      path: "networks",
    },
    {
      name: "Blockchain",
      path: "blockchain",
    },
  ];

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const navigate = useNavigate();

  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY);
    };
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const getPath = useLocation().pathname.split("/")?.[1];

  return (
    <>
      <div
        className={`text-white my-auto py-4 items-center sticky top-0 z-[1002] ${
          scroll > 50 ? "!bg-black" : "bg-transparent"
        }`}
      >
        <div className="container mx-auto flex justify-between">
          <Link to="/">
            <div className="flex items-center gap-x-2 cursor-pointer">
              <img src={logo} alt="logo" />
              <p>BETA</p>
            </div>
          </Link>

          <div className="flex gap-6">
            <div className="flex gap-6 items-center">
              {navElements.map((item, index) => (
                <NavLink to={`/${item.path}`} key={index}>
                  <button
                    disabled={item?.path !== "transactions"}
                    className={`uppercase cursor-pointer py-2 px-4 rounded-sm text-white hover:bg-gradient-to-r hover:from-[#DC7896] hover:to-[#9E59FE] hover:text-transparent bg-clip-text transition-all duration-300 ease-in-out ${
                      getPath === item.path && "active-class"
                    } ${item.path !== "transactions" && "!cursor-default"}`}
                  >
                    {item.name}
                  </button>
                </NavLink>
              ))}
            </div>

            <button
              className="navbar-btn rounded-lg font-medium uppercase m-auto"
              onClick={toggleMenu}
            >
              <ConnectButton label={"CONNECT WALLETS"} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
